.viewer-container {
  overflow: auto;
  position: absolute;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.viewer-container::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.pdf-container{
  margin-top: 50px;
}

.snippet-highlight{
  opacity: 1;
  z-index: 1;
  pointer-events: none
}

.page {
  border-image: none !important;
}
