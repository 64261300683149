.browseSectionHeader {
  margin-top: 16px;
  margin-left: 32px;
  font-weight: bold;
}

.bookCard {
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
  margin-left: 32px;
  cursor: pointer;
  background-color: white;
  position: relative;
}

.bookTitle {
  font-weight: bold;
}

.bookTitle {
  margin-top: 16px;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.bookAuthors {
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.hoverCardButtons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .9); /* fallback for no blur */
  border-radius: 8px;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: blur(1em)) or (backdrop-filter: blur(1em))) {
  .hoverCardButtons {
    background-color: rgba(255, 255, 255, .5);
    -webkit-backdrop-filter: blur(1em);
    backdrop-filter: blur(1em);
  }
}

.openBookButton {
  padding: 8px;
  text-align: center;
  color: white;
  background-color: #FD930D;
  width: 50%;
  border-radius: 100px;
  font-weight: bold;
  align-self: center;
}

.openBookButton:hover {
  background-color: #FD930DD0;
}
