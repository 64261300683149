.burntToast {
  background-color: #170312 !important;
  border-color: #170312 !important;
  color: #FFFAFB !important;
  font-family: Quicksand !important;
  align-self: center;
}

.StyledButton-sc-323bzc-0.hVtTwC, .StyledButton-sc-323bzc-0.ervBpH, .StyledButton-sc-323bzc-0.jseTGO, .StyledButton-sc-323bzc-0.fxvSEv {
  font-weight: 600 !important;
  color: white !important;
}

.copyrightText {
  margin-top: 16px;
  display: block;
}

.buckleafLogo {
  width: 250px;
  height: 50px;
  background-image: url('res/buckleaf-logo-full.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.buckleafLogoNavbar {
  width: 125px;
  height: 25px;
  background-image: url('res/buckleaf-logo-full.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.routerLink {
  text-decoration: none;
}

.textField {
  border-color: rgba(0, 0, 0, 0) !important;
  background-color: rgb(245, 245, 245) !important;
  outline: none;
  height: 35px;
}

.textFieldFloating {
  border-color: rgba(0, 0, 0, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  outline: none;
  height: 35px;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 0ms ease-in;
}
