.navbar {
  margin: 16px 16px 0 16px;
  height: 50px;
  border-radius: 25px;
  flex-shrink: 0;
  background-color: white;
  position: fixed;
  width: calc(100% - 32px);
  z-index: 100;
}

.searchForm {
  width: 500px;
}
